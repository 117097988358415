import {ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {Observable, Subject, takeUntil} from "rxjs";
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    UntypedFormControl,
    ValidatorFn,
    Validators
} from "@angular/forms";
import {Project} from "@core/services/backend/common/interface/projects";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {aioTableLabels} from "@app/pages/activity-management/activity-planning/activity-planning.component";
import {ProjectsService} from "@core/services/backend/common/service/projects.service";
import {UsersService} from "@core/services/backend/common/service/users.service";
import {fadeInUp400ms} from "@vex/animations/fade-in-up.animation";
import {stagger40ms} from "@vex/animations/stagger.animation";
import {MatDialog} from "@angular/material/dialog";
import {WorkedHour} from "@core/services/backend/common/interface/workedHours";
import {WorkedHoursService} from "@core/services/backend/common/service/workedHours.service";
import {DateAdapter, MAT_DATE_LOCALE} from "@angular/material/core";
import moment from "moment";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {AuthService} from "@core/guard/auth-service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {map, startWith} from "rxjs/operators";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {MatFormFieldAppearance} from "@angular/material/form-field";
import {
    ActivityAssignmentCommentsComponent
} from "@app/pages/activity-management/activity-assignment-comments/activity-assignment-comments.component";
import {User} from "@core/services/backend/common/interface/users";
import {th} from "date-fns/locale";

@Component({
    selector: 'vex-hours-worked',
    templateUrl: './hours-worked.component.html',
    styleUrls: ['./hours-worked.component.scss'],
    animations: [
        fadeInUp400ms,
        stagger40ms
    ]
})
export class HoursWorkedComponent implements OnInit {

    private readonly destroying$ = new Subject<void>();

    layoutCtrl = new UntypedFormControl('boxed');
    searchForm: FormGroup;
    projectList: Project[] = [];
    filteredProjectList: Observable<Project[]>;
    filteredUserList: Observable<User[]>;
    filteredOptions: Observable<any[]>[] = [];
    projectListAuto: Project[] = [];
    userListAuto: User[] = [];

    @ViewChild('start') start: ElementRef;
    @ViewChild('end') end: ElementRef;

    weekObj = {};

    IS_SUPER_ADMIN = false;
    formIsDirty: boolean = false;
    isEditable: boolean = false;
    force_comments: boolean = false;

    getAppearance: MatFormFieldAppearance = 'legacy'
    projectSelected: Project;
    userSelected: User;

    workedHoursList: WorkedHour[] = [];
    workedHoursEliminatedList: WorkedHour[] = [];

    dataSource: MatTableDataSource<AbstractControl<any>>;
    weekTotalHours: number = 0;
    weekTotalExtraHours: number = 0;

    firstDayOfLastWeek = moment().subtract(1, 'weeks').startOf('week');
    firstMondayOfLastWeek = this.firstDayOfLastWeek.day(1);
    //minDate: Date;

    startOfWeek: moment.Moment = moment().add(-1, 'day').startOf('week');
    endOfWeek: moment.Moment = moment().add(-1, 'day').endOf('week');

    btnPrevDisabled: boolean = true;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    displayedColumns: string[] = ['date', 'project', 'count', 'extras', 'observation', 'actions'];
    data = {
        projectSelected: ''
    }
    day = {
        date: '',
        count: 0,
        extras: 0,
        rechazadas: 0
    }
    days = []

    pageSize = 5;
    pageSizeOptions: number[] = [5, 10, 20, 50];
    labels = aioTableLabels;
    isLoading: boolean;
    VOForm: FormGroup;
    formSubmitted = false;
    loading: boolean;

    constructor(private projects: ProjectsService,
                private users: UsersService,
                private dialog: MatDialog,
                private fb: FormBuilder,
                private _formBuilder: FormBuilder,
                private workedHoursService: WorkedHoursService,
                private dateAdapter: DateAdapter<any>,
                private translateService: TranslateService,
                @Inject(MAT_DATE_LOCALE) private _locale: string,
                private cdr: ChangeDetectorRef,
                private authService: AuthService,
                private snackbar: MatSnackBar
    ) {

        translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.updateLanguage();
        });

        this.IS_SUPER_ADMIN = authService.Role === 'SUPER_ADMIN'

    }

    ngOnInit() {

        if (this.IS_SUPER_ADMIN) {
            this.searchForm = new FormGroup({
                startDate: new FormControl({value: this.startOfWeek.add(1, 'day').toDate(), disabled: false}),
                endDate: new FormControl({value: this.endOfWeek.add(1, 'day').toDate(), disabled: false}),
                project: new FormControl(),
                user: new FormControl()
            })
        } else {
            this.searchForm = new FormGroup({
                startDate: new FormControl({value: this.startOfWeek.add(1, 'day').toDate(), disabled: false}),
                endDate: new FormControl({value: this.endOfWeek.add(1, 'day').toDate(), disabled: false}),
                project: new FormControl(),
            })
        }


        this.reloadData(true);

    }

    minDate() {
        if (!this.IS_SUPER_ADMIN) {
            return new Date(this.firstMondayOfLastWeek.year(), this.firstMondayOfLastWeek.month(), this.firstMondayOfLastWeek.day())
        }
        return null;
    }

    async reloadData(bool: boolean) {


        if (!bool) {
            this.searchForm.controls['startDate'].patchValue(this.startOfWeek.add(1, 'day').toDate())
            this.searchForm.controls['endDate'].patchValue(this.endOfWeek.add(1, 'day').toDate())
        }

        this.dateAdapter.getFirstDayOfWeek = () => 1;
        //this.minDate = new Date(this.firstMondayOfLastWeek.year(), this.firstMondayOfLastWeek.month(), this.firstMondayOfLastWeek.day())

        var date = moment(this.searchForm.controls['startDate'].value), weeklength = 7;
        while (weeklength--) {
            this.day = {
                date: date.format("DD-MM-YYYY"),
                count: 0,
                extras: 0,
                rechazadas: 0
            }
            this.days.push(this.day);
            date.add(1, "day")
        }
        //console.log(this.days)

        this.VOForm = this._formBuilder.group({
            VORows: this._formBuilder.array([])
        });

        this.loadData();

    }

    loadData() {

        let that = this;


        this.loading = false;
        this.initializeData().then(value => {

            this.VOForm = this.fb.group({
                VORows: this.fb.array(this.workedHoursList.map(val => {

                        // @ts-ignore
                        let projectName = val.project ? this.getProjectName(val.project.name, val.project.code) : '';

                        const form = this.fb.group({
                            id: new FormControl(val.ID),
                            date: new FormControl(val.date, Validators.required),
                            project: new FormControl(projectName),
                            count: new FormControl(val.count, Validators.required),
                            extras: new FormControl(val.extras, Validators.required),
                            comments: new FormControl(val.comments),
                            status: new FormControl(val.status),
                            hasComments: new FormControl(val.statusComment),
                            isEditable: new FormControl(true),
                            isNewRow: new FormControl(false),
                        });

                        that.force_comments = false;
                        form.get('count').valueChanges.subscribe((count) => {
                            // @ts-ignore
                            if (val.project.force_comments) {
                                that.force_comments = true;
                                form.controls.comments.setValidators(that._sectorValidator(form))
                                form.controls.comments.updateValueAndValidity();
                            }
                        });

                        form.get('extras').valueChanges.subscribe((count) => {
                            // @ts-ignore
                            if (val.project.force_comments) {
                                that.force_comments = true;
                                form.controls.comments.setValidators(that._sectorValidator(form))
                                form.controls.comments.updateValueAndValidity();
                            }
                        });

                        form.get('comments').valueChanges.subscribe((count) => {
                            // @ts-ignore
                            if (val.project.force_comments && count !== '' ){
                                form.controls.comments.updateValueAndValidity({onlySelf: false, emitEvent: false})
                                form.controls.comments.clearValidators();
                            }
                            // @ts-ignore
                            else if (val.project.force_comments && count === '' ) {
                                form.controls.comments.setValidators(that._sectorValidator(form))
                                form.controls.comments.updateValueAndValidity({onlySelf: false, emitEvent: false})
                            }

                        });

                        if (val.status === 'ACEPTADA' && !this.IS_SUPER_ADMIN) {
                            form.controls.isEditable.setValue(false);
                            form.controls.count.disable({emitEvent: false});
                            form.controls.extras.disable({emitEvent: false});
                            form.controls.comments.disable({emitEvent: false});
                        }

                        // @ts-ignore
                        if ( !val.project.normal_hours ) {
                            form.controls.count.disable({emitEvent: false});
                        }

                        // @ts-ignore
                        if ( !val.project.extra_hours ) {
                            form.controls.extras.disable({emitEvent: false});
                        }
                        that.cdr.detectChanges();
                        return form;

                    }
                )) //end of fb array
            }); // end of form group cretation

            this.VOForm.valueChanges.subscribe((value: any) => {
                this.formIsDirty = this.VOForm.dirty;
                //console.log(`Is form dirty?: ${this.VOForm.dirty}`);
            });

            this.isLoading = false;
            this.dataSource = new MatTableDataSource((this.VOForm.get('VORows') as FormArray).controls);
            const control = this.VOForm.get('VORows') as FormArray;
            for (let i = 0; i < control.controls.length; i++) {
                this.ManageNameControl(i);
                const arrayControl = this.VOForm.get('VORows') as FormArray;
                // @ts-ignore
                let auto = arrayControl.at(i).get('project');
                // @ts-ignore
                /*                const project = this.projectList.filter(p => parseInt(p.ID) === parseInt(auto.value));
                                if (project.length > 0) {
                                    auto.setValue(project[0]);
                                }*/
            }
            ;
            this.dataSource.paginator = this.paginator;

            const filterPredicate = this.dataSource.filterPredicate;
            this.dataSource.filterPredicate = (data: AbstractControl, filter) => {
                return filterPredicate.call(this.dataSource, data.value, filter);
            }

        });
    }

    _sectorValidator(form): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const count = form?.controls['count'].value;
            const extras = form?.controls['extras'].value;
            const comments = form?.controls['comments'].value;
            if ((count !== 0 || extras !== 0 ) && ( comments === '')) {
                return {conditionalRequired: true};
            }
            return null;
        };
    }



    async initializeData() {
        const that = this;
        return await new Promise<void>((resolve, reject) => {

            let list = this.users.list('', 0, 10000, '', that.IS_SUPER_ADMIN);
            list.pipe(takeUntil(this.destroying$)).subscribe((data) => {

                // @ts-ignore
                this.userListAuto = data.data;
                this.userListAuto.forEach(user => {
                    user.name = user.lastName + ' ' + user.firstName;
                })

                if (this.IS_SUPER_ADMIN) {
                    this.filteredUserList = this.searchForm.get('user')!.valueChanges.pipe(
                        startWith(''),
                        map(value => {
                            const name = typeof value === 'string' ? value : value?.name;
                            return name ? this._filterUser(name as string) : this.userListAuto.slice();
                        }),
                    );
                }

            });

            const search = {
                active: that.IS_SUPER_ADMIN ? '' : 'true'
            }


            list = this.projects.list(JSON.stringify(search), 0, 10000, '', that.IS_SUPER_ADMIN);
            list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
                // @ts-ignore
                this.projectList = data.data;
                // @ts-ignore
                this.projectListAuto = data.data;

                this.filteredProjectList = this.searchForm.get('project')!.valueChanges.pipe(
                    startWith(''),
                    map(value => {
                        const name = typeof value === 'string' ? value : value?.name;
                        return name ? this._filter(name as string) : this.projectListAuto.slice();
                    }),
                );

                this.projectListAuto.forEach(project => {
                    project.name = project.code + " - " + project.name;
                })

                let projectId = 0;
                if (that.searchForm.controls['project'].value !== null) {
                    /*this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
                        this.snackbar.open(res.PROYECTO.ERROR_NO_SELECCIONADO, '', {
                            duration: 3000,
                            panelClass: ['red-snackbar']
                        });
                    });
                    return*/
                    projectId = that.searchForm.controls['project'].value.ID;
                    //that.projectName= that.searchForm.controls['project'].value.name + ' - ' + that.searchForm.controls['project'].value.code;
                }

                let user_active: number = Number(that.authService.UserId);
                if (that.userSelected) {
                    user_active = that.userSelected.ID;
                }

                const search = {
                    userId: user_active,
                    startDate: that.startOfWeek.toDate(),
                    endDate: that.endOfWeek.toDate()
                }

                console.log(search);
                const list = this.workedHoursService.list(JSON.stringify(search), 0, 10000, '');
                list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
                    // @ts-ignore
                    //that.workedHoursList = data.data;

                    let hoursTotal = data.data;
                    this.days = []
                    let date = moment(this.startOfWeek), weeklength = 7;
                    while (weeklength--) {
                        this.day = {
                            date: date.format("DD-MM-YYYY"),
                            count: 0,
                            extras: 0,
                            rechazadas: 0
                        }
                        this.days.push(this.day);
                        date.add(1, "day")
                    }
                    that.weekTotalHours = 0;
                    that.weekTotalExtraHours = 0;

                    hoursTotal.forEach(hour => {

                        hour.dateSort = moment(hour.date).format('YYYYMMDD');
                        hour.date = moment(hour.date).format('DD-MM-YYYY');
                        that.weekTotalHours += parseFloat(String(hour.count));
                        that.weekTotalExtraHours += parseFloat(String(hour.extras));

                        let days = this.days.filter(day => day.date === hour.date);
                        if (days.length > 0) {
                            days[0].count += parseFloat(String(hour.count));
                            days[0].extras += parseFloat(String(hour.extras));
                            days[0].rechazadas += hour.status === 'RECHAZADA' ? 1 : 0;
                        }

                    })



                    if (projectId !== 0) {

                        hoursTotal = hoursTotal.filter(hour => hour.projectId === projectId);

                        for (var m = moment(that.startOfWeek); m.isBefore(that.endOfWeek); m.add(1, 'days')) {

                            let hh: WorkedHour = ({
                                ID: 0,
                                projectId: 1,
                                userId: Number(that.authService.UserId),
                                date: m.format('DD-MM-YYYY'),
                                count: 0,
                                extras: 0,
                                comments: '',
                                status: 'PENDIENTE',
                                selected: false,
                                statusComment: '',
                            });

                            // @ts-ignore
                            hh.dateSort =  m.format('YYYYMMDD')

                            if (projectId !== 0) {
                                // @ts-ignore
                                hh.project = that.projectSelected;
                            }

                            const result = hoursTotal.filter(hour => {
                                return hour.date === m.format('DD-MM-YYYY') && hour.projectId === projectId;
                            });
                            if (result.length === 0) {
                                hoursTotal.push(hh)
                            }

                        }
                    }
                    let hoursSorted = hoursTotal.sort((a, b) => parseFloat(a.dateSort) - parseFloat(b.dateSort) );
                    that.workedHoursList = [...hoursSorted];
                    console.log(that.workedHoursList)
                    resolve();

                   /* const search = {
                        userId: user_active,
                        startDate: that.startOfWeek.toDate(),
                        endDate: that.endOfWeek.toDate(),
                        projectId: projectId
                    }
                    const list = this.workedHoursService.list(JSON.stringify(search), 0, 10000, '');
                    list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
                        // @ts-ignore
                        //that.workedHoursList = data.data;
                        const hours = data.data;

                        hours.forEach(hour => {
                            let date = new Date(hour.date);
                            hour.dateSort = moment(hour.date).format('YYYYMMDD');
                            hour.date = moment(hour.date).format('DD-MM-YYYY');
                        })

                        if (projectId !== 0) {
                            for (var m = moment(that.startOfWeek); m.isBefore(that.endOfWeek); m.add(1, 'days')) {

                                let hh: WorkedHour = ({
                                    ID: 0,
                                    projectId: 1,
                                    userId: Number(that.authService.UserId),
                                    date: m.format('DD-MM-YYYY'),
                                    count: 0,
                                    extras: 0,
                                    comments: '',
                                    status: 'PENDIENTE',
                                    selected: false,
                                    statusComment: '',
                                });

                                // @ts-ignore
                                hh.dateSort =  m.format('YYYYMMDD')

                                if (projectId !== 0) {
                                    // @ts-ignore
                                    hh.project = that.projectSelected;
                                }

                                const result = hours.filter(hour => {
                                    return hour.date === m.format('DD-MM-YYYY');
                                });
                                if (result.length === 0) {
                                    hours.push(hh)
                                }
                            }
                        }
                        let hoursSorted = hours.sort((a, b) => parseFloat(a.dateSort) - parseFloat(b.dateSort) );
                        that.workedHoursList = [...hoursSorted];
                        console.log(that.workedHoursList)
                        resolve();
                    });*/
                });
            });
        });
    }

    ManageNameControl(index: number) {
        /*        const arrayControl = this.VOForm.get('VORows') as FormArray;
                this.filteredOptions[index] = arrayControl.at(index).get('project').valueChanges
                    .pipe(
                        startWith<string | Project>(''),
                        map(value => typeof value === 'string' ? value : value.name),
                        map(name => name ? this._filter(name) : this.projectList.slice())
                    );*/
    }

    private _filter(name: string): Project[] {
        const filterValue = name.toLowerCase();
        return this.projectList.filter(option => option.name.toLowerCase().indexOf(filterValue) != -1);
    }

    private _filterUser(name: string): User[] {
        const filterValue = name.toLowerCase();
        return this.userListAuto.filter(option => option.name.toLowerCase().indexOf(filterValue) != -1);
    }

    DeleteSVO(VOForm, VOFormElement, i) {

        const control = this.VOForm.get('VORows') as FormArray;

        //if (!this.isEditable) {
            this.workedHoursEliminatedList.push({
                comments: "",
                count: 0,
                date: "",
                projectId: 0,
                userId: 0,
                extras: 0,
                status: "PENDIENTE",
                selected: false,
                statusComment: "",
                ID: VOFormElement.value['id']
            })
            VOForm.get('VORows').removeAt(i);
            // remove from filteredOptions too.
            this.filteredOptions.splice(i, 1);
        // } else {
        //
        //     // @ts-ignore
        //     control.controls[i].controls['count'].setValue(0);
        //     // @ts-ignore
        //     control.controls[i].controls['extras'].setValue(0);
        //     // @ts-ignore
        //     control.controls[i].controls['comments'].setValue('');
        //     // @ts-ignore
        //     control.controls[i].controls['project'].setValue('');
        // }
        this.dataSource = new MatTableDataSource(control.controls)
    }

    displayProjectFn(project: Project) {
        if (project) {
            return project.name;
        }
    }

    displayUserFn(user: User) {
        if (user) {
            return user.name;
        }
    }

    save() {
        this.loading = true;
        /*        if (this.searchForm.controls['project'].value === null) {
                    this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
                        this.snackbar.open(res.PROYECTO.ERROR_NO_SELECCIONADO, '', {
                            duration: 3000,
                            panelClass: ['red-snackbar']
                        });
                    });
                    return
                };*/
        this.formSubmitted = true;
        const that = this;
        const payload = []
        const control = this.VOForm.get('VORows') as FormArray;

        if (this.VOForm.get('VORows').valid) {

            if (typeof this.searchForm.controls['project'].value !== 'undefined' && this.searchForm.controls['project'].value !== null) {
                control.controls.forEach(control => {


                    const record: {} = {}
                    // @ts-ignore
                    record.id = control.controls.id.value;
                    // @ts-ignore
                    record.projectId = this.searchForm.controls['project'].value.ID;

                    let user_active: number = Number(that.authService.UserId);
                    let user_active_name: string = that.authService.User;
                    if (that.userSelected) {
                        user_active = that.userSelected.ID;
                        user_active_name = that.userSelected.firstName + ' ' + that.userSelected.lastName;
                    }
                    // @ts-ignore
                    record.statusComments = moment().format("DD-MM-YYYY") + "|" +  user_active_name + "|PENDIENTE|" +  control.controls.comments.value + "##";
                    // @ts-ignore
                    record.status = "PENDIENTE";
                    // @ts-ignore
                    record.userId = user_active;
                    // @ts-ignore
                    record.date = moment(control.controls.date.value, "DD-MM-YYYY", true).format();
                    // @ts-ignore
                    record.count = control.controls.count.value;
                    // @ts-ignore
                    record.extras = control.controls.extras.value;
                    // @ts-ignore
                    record.comments = control.controls.comments.value;
                    // @ts-ignore
                    record.userUpdatedId = user_active;
                    // @ts-ignore
                    record.userUpdated = user_active_name;
                    // @ts-ignore
                    record.userApproved = user_active_name;
                    // @ts-ignore
                    record.isAddOrUpdate = true

                    // @ts-ignore
                    if (record.comments !== '' || record.count > 0 || record.extras > 0) {
                        payload.push(record);
                    }

                });
            }

            this.workedHoursEliminatedList.forEach((hour) => {
                const record: {} = {}
                // @ts-ignore
                record.id = hour.ID
                // @ts-ignore
                record.isDeleted = true
                payload.push(record)
            });

            this.workedHoursService.create(payload).subscribe({
                next(result) {
                    that.handleSuccessResponse();
                    this.loading = false;
                    that.reset();
                    that.userSelected = null;
                    that.loadData();
                },
                error(err) {
                    this.loading = false;
                    that.handleWrongResponse(err);
                    that.userSelected = null;
                    that.reset();
                    that.loadData();
                }
            });
        } else {
            this.VOForm.get('VORows').markAllAsTouched();
            this.loading = false;
        }
    }


    handleSuccessResponse() {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            this.snackbar.open(res.LABELS.SUCCESS, '', {
                duration: 3000,
                panelClass: ['green-snackbar']
            });
        });

    }

    handleWrongResponse(err) {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            this.snackbar.open(res.LABELS.ERROR, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });
        });
    }

    private getSearch() {
        const search = {
            projectId: 0,
            name: '%' + this.searchForm.get("project").value.toString().toUpperCase() + '%'
        }
        return search;
    }

    private updateLanguage() {
        this._locale = this.translateService.currentLang;
        this.dateAdapter.setLocale(this._locale);
    }

    BackWeek() {
        const start = this.searchForm.controls['startDate'].value;
        this.startOfWeek = moment(start).add(-1, 'week').startOf('week');
        this.endOfWeek = moment(start).add(-1, 'week').endOf('week');
        this.reloadData(false);
        this.enablePrevButton();
    }

    NextWeek() {
        const start = this.searchForm.controls['startDate'].value;
        this.startOfWeek = moment(start).add(1, 'week').startOf('week');
        this.endOfWeek = moment(start).add(1, 'week').endOf('week');
        this.reloadData(false);
        this.enablePrevButton();
    }

    enablePrevButton() {
        var duration = moment.duration(this.startOfWeek.diff(moment()));
        var weeks = duration.asWeeks()
        if (!this.IS_SUPER_ADMIN) {
            this.btnPrevDisabled = weeks > -1;
        }
    }

    onSelectionChange(event: MatAutocompleteSelectedEvent) {
        this.projectSelected = event.option.value;
        this.isEditable = false;
        this.getAppearance = 'outline';
        this.reloadData(true);
    }

    onSelectionUserChange(event: MatAutocompleteSelectedEvent) {
        this.userSelected = event.option.value;
        this.isEditable = true;
        this.getAppearance = 'legacy';
        this.reloadData(true)
        //console.log('onSelectionChange called', event.option.value);
    }

    dateRangeChange(dateRangeStart: any, dateRangeEnd: any) {
        const start = this.searchForm.controls['startDate'].value;
        this.startOfWeek = moment(start).startOf('week');
        this.endOfWeek = moment(start).endOf('week');
        this.reloadData(false);
        //this.reloadData(true);
    }

    reset() {
        if (this.IS_SUPER_ADMIN) {
            this.searchForm.controls['user'].setValue(null);
        }
        this.searchForm.controls['project'].setValue(null);
        this.projectSelected = null;
        this.isEditable = false;
        this.getAppearance = 'legacy';
        this.reloadData(true);
    }

    resetUser() {
        this.searchForm.controls['project'].setValue(null);
        if (this.IS_SUPER_ADMIN) {
            this.searchForm.controls['user'].setValue(null);
        }
        this.userSelected = null;
        this.isEditable = false;
        this.getAppearance = 'legacy';
        this.reloadData(true);
    }

    getProjectValue(VOForm: FormGroup, i) {
        if (typeof VOForm.get('VORows').value[i] !== 'undefined') {
            return VOForm.get('VORows').value[i].project;
        }
        return "";
    }

    getProjectName(name: string, code: string) {
        let result = name;
        if (name.indexOf(code) === -1) {
            result = code + ' - ' + name;
        }
        return result;
    }

    statusControl(VOForm: FormGroup, i) {
        // @ts-ignore
        // console.log(control.controls[i].controls['status'].value);
        // @ts-ignore
        return VOForm.get('VORows').value[i] ? VOForm.get('VORows').value[i].status !== 'ACEPTADA' : true;
    }

    hasComments(VOForm: FormGroup, i) {
        // @ts-ignore
        // console.log(control.controls[i].controls['status'].value);
        // @ts-ignore
        return VOForm.get('VORows').value[i] ? VOForm.get('VORows').value[i].hasComments !== '' : false;
    }

    showComments(VOForm: FormGroup, i) {

        if (VOForm.get('VORows').value[i]) {

            const data = {
                user: this.authService.User,
                project: VOForm.get('VORows').value[i].project,
                date: VOForm.get('VORows').value[i].date,
                comments: VOForm.get('VORows').value[i].comments,
                statusComments: VOForm.get('VORows').value[i].hasComments
            }
            this.dialog.open(ActivityAssignmentCommentsComponent, {
                data: data,
                disableClose: false,
                width: '800px',
                height: '400px'
            }).afterClosed().subscribe(result => {
            });

        }


    }
}
